<template>
    <v-theme-provider dark>
        <base-section id="social" class="text-center" space="56">
            <base-icon class="mb-8">mdi-twitter</base-icon>

            <base-section-heading color="transparent" title="Social Media"
                >You can follow us on twitter, see how people who benefited from
                this company are singing our praises</base-section-heading
            >

            <a
                class="d-inline-block mb-8"
                href="https://sylvaleoglobal.com"
                style="text-decoration: none"
                target="_blank"
                >https://sylvaleoglobal.com</a
            >

            <div class="py-4" />

            <v-btn
                class="font-weight-bold"
                color="white"
                href="https://twitter.com/SylvaleoL?s=08"
                light
                min-width="168"
                target="_blank"
                x-large
            >
                Follow Us
                <v-icon right>mdi-open-in-new</v-icon>
            </v-btn>
        </base-section>
    </v-theme-provider>
</template>

<script>
export default {
    name: 'SectionNews',

    provide: {
        heading: { align: 'center' },
    },
};
</script>
<style scoped>
#social {
    background-color: #0b2571;
}
</style>
